<template>
  <div class="mb-5 m-3 flex justify-content-center flex-column align-items-center w-12">
    <a class="block" style="font-size: 2rem">The site is currently under maintenance!</a>
    <small class="block m-2 mt-3" style="word-break: break-all">
      Are you authorized?
      <a @click="gotoLogin" class="text-primary-500 underline cursor-pointer">Log in</a>
    </small>
  </div>
</template>

<script>
export default {
  name: "MaintenancePage",
  props: [
      'isMobile'
  ],
  methods: {
    gotoLogin: function() {
      this.$setSection(this, 'Home');
    }
  }
}
</script>

<style scoped>

</style>