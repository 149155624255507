<template>
  <div class="flex justify-content-between flex-column w-full h-full bg-cyan-100">
    <div class="w-full bg-blue-200 flex align-items-center" style="color:white;"
         :style="{height:(isMobile ? '8%' : '5%')}">
      <div class="flex w-10 ml-2 align-items-center">
        <a class="pi pi-angle-left pi-link hover:text-color border-noround"
           :style="{fontSize:(isMobile ? '2.5rem' : '1.5rem')}"
           @click="setSection('Home')"/>
        <div class="flex flex-column align-items-start ml-1">
          <span :class="{'text-lg':isMobile, 'text-base':!isMobile}" class="font-bold" v-if="currentChat">{{ currentChat.name }}</span>
          <span :class="{'text-base':isMobile, 'text-xs':!isMobile}" class="font-normal" v-if="isMobile && currentChat && currentChat.isTyping">Typing...</span>
        </div>
      </div>
      <div class="flex justify-content-end w-2 mr-2">
        <a class="pi pi-bars hover:text-color border-noround"
           :style="{fontSize:(isMobile ? '2.5rem' : '1.5rem')}"
           @click="toggle"
           aria-haspopup="true"
           aria-controls="overlay_settings_menu"/>
      </div>
    </div>
    <Menu ref="menu" id="overlay_settings_menu" :model="items" :popup="true" />
    <div id="chat" class="w-full flex flex-column overflow-y-auto"
         :style="{height:(isMobile ? '84%' : '90%')}">
      <div v-if="currentChat">
        <div v-for="(message) in currentChat.messages" :key="message.id"
             class="flex"
             :class="{'fadein animation-duration-500':message.isNew && message.from === 'You',
                      'fadeinleft animation-duration-500':message.isNew && message.from !== 'You',
                      'justify-content-end':message.from === 'You',
                      'justify-content-start':message.from !== 'You'}">
          <div class="w-max h-max flex p-3 m-2 border-round-md flex-column justify-content-start align-items-start"
               style="max-width: 60%"
               :class="{'bg-blue-200':message.from === 'You',
                        'bg-blue-300':message.from !== 'You'}">
            <span class="font-bold mb-1">{{ message.from }}</span>
            <span class="text-left" style="word-break: break-all">{{ message.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile && currentChat && currentChat.isTyping"
         class="flipup animation-duration-1000 absolute bottom-0 ml-2"
         style="height: 4.9rem">
      <span>Anonymous is typing...</span>
    </div>
    <div class="w-full bg-blue-500 flex align-items-center px-2 justify-content-around"
         :style="{height: (isMobile ? '8%' : '3.3925rem')}">
      <div class="flex lg:w-10 align-items-center"
           :class="{'w-9':!isMobile}"
           :style="{width: (isMobile ? '87%' : '')}">
          <Textarea
              @keypress.enter.exact.prevent="sendMessage"
              @keypress.enter.shift.exact.prevent="() => {this.messageText += '\n'}"
              id="textArea" v-model="messageText"
              class="h-2rem w-full"
              placeholder="Text here..." />
      </div>
      <div class="flex lg:w-2 align-items-center justify-content-center"
           :class="{'w-1':isMobile, 'w-3':!isMobile}">
        <Button @click="sendMessage" class="bg-primary-600" icon="pi pi-send" v-if="isMobile" rounded/>
        <Button @click="sendMessage" class="w-10 bg-primary-600 border-round-lg hover:bg-primary-700" icon="pi pi-send" label="Send" v-else/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const menu = ref();

const items = ref([{
  label: "Settings",
  icon: "pi pi-cog"
}])

const toggle = (event) => {
  menu.value.toggle(event);
};
</script>

<script>
import Menu from "primevue/menu"
import Textarea from "primevue/textarea"
import Button from "primevue/button"

export default {
  name: "UserChat",
  props: [
      'isMobile'
  ],
  components: {
    Menu,
    Textarea,
    Button
  },
  data() {
    return {
      stomp: null,
      currentChatId: null,
      currentConversationId: null,
      currentChat: null,
      messageText: ''
    }
  },
  methods: {
    init: async function() {
      this.currentChatId = sessionStorage.getItem("currentPage").split('#')[1];
      if(this.currentChatId) {
        await this.$httpAuthGet(this, '/api/chats/' + this.currentChatId, {}).then( async (response) => {
          this.currentChat = await response.data;
          this.currentChat.messages = await JSON.parse(response.data.messages);
          this.currentConversationId = await response.data.convId;
          this.scrollDown();

          await this.connectToChat();
        });

        let imTyping = false
        let timeout;

        document.getElementById("textArea").addEventListener("input", () => {
          clearTimeout(timeout);
          if(!imTyping) {
            imTyping = true;
            this.$WS.send(`/conversation/${this.currentConversationId}`, `/typing`, "OK");
          }
          timeout = setTimeout(() => {
            this.$WS.send(`/conversation/${this.currentConversationId}`, `/typing`, "KO");
            imTyping = false;
          }, 1000);
        });

      }
    },
    setSection: function(x) {
      this.$setSection(this, x);
    },
    scrollDown: function() {
      let chat = document.getElementById("chat");
      if(chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    },
    connectToChat: function(){
      this.$WS.listen(`/conversation/${this.currentConversationId}`, async (message) => {

        let data = await JSON.parse(message.body);
        if(data.typing != null) {
          this.currentChat.isTyping = data.typing
        } else {
          data.isNew = true;
          await this.currentChat.messages.push(data);
          this.scrollDown();
        }
      });
    },
    sendMessage: async function() {
      let text = this.messageText.trim().trim();
      this.messageText = "";
      if (text !== '') {
        await this.currentChat.messages.push({
          "isNew": true,
          "from": "You",
          "text": text
        });
        await this.scrollDown();

        let body = `{"message":${JSON.stringify(text)}}`;
        await this.$httpAuthPost(this, `/api/chats/sendMessage/${this.currentConversationId}`, body, {}, false);
      }

    }
  },
  beforeMount() {
    this.init();
  },
  unmounted() {
    this.$WS.leave(`/conversation/${this.currentConversationId}`);
  }
}
</script>

<style>
  .p-inputtextarea {
    padding: 5px 0 0 5px;
    resize: none;
  }
</style>