<template>
  <Menu :model="items" class="md:block w-min md:w-4 lg:w-3 xl:w-2 hidden" v-if="!isMobile">
    <template #start>
      <svg width="200" height="20" fill="none" viewBox="0 0 200 20" xmlns="http://www.w3.org/2000/svg" class="center h-2rem">
        <image href="../assets/svg/logo-no-background.svg" x="0" y="0" width="200px" height="20px"/>
      </svg>
    </template>
    <template #submenuheader="{ item }">
      <span class="text-primary font-bold">{{ item.label }}</span>
    </template>
    <template #item="{ item, props }">
      <a class="flex overflow-hidden align-items-center" v-bind="props.action"
         :class="{'selected':$checkSection(item.label)}"
         @click="setSection(item.goto + (item.id ? '#' + item.id : '') )">
        <span class="flex-none" :class="item.icon" />
        <span class="flex-grow-1 inline-flex align-items-start flex-column ml-2">
            <span class="font-bold">{{ item.label }}</span>
            <span class="text-sm">{{ item.customDescription }}</span>
        </span>
        <span class="flex-none ml-2" v-if="item.badgeSeverity">
          <Badge :value="item.badgeValue ? item.badgeValue : '&nbsp'" :severity="item.badgeSeverity" />
        </span>
        <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
      </a>
    </template>
    <template #end>
      <div id="profile" class="w-full">
        <button class="p-menuitem-separator m-0 relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                aria-controls="overlay_profile_menu"
                @click="toggle"
                v-if="username !== '' && role !== ''">
          <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2" shape="circle" />
          <span class="inline-flex flex-column">
              <span class="font-bold">{{ username }}</span>
              <span class="text-sm">{{ role }}</span>
          </span>
        </button>
      </div>
    </template>
  </Menu>
  <Menubar class="md:hidden bg-white z-1" style="height:6%" v-else>
    <template #start>
      <svg width="200" height="20" fill="none" viewBox="0 0 200 20" xmlns="http://www.w3.org/2000/svg" class="center h-2rem">
        <image href="../assets/svg/logo-no-background.svg" x="0" y="0" width="200px" height="20px"/>
      </svg>
    </template>
    <template #end>
      <div class="flex align-items-center gap-2">
        <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2" shape="circle"
                @click="toggle"/>
      </div>
    </template>
  </Menubar>
  <Menu ref="menu" id="overlay_profile_menu" :model="itemsProfile" :popup="true">
    <template #submenuheader="{ item }">
      <span class="block text-primary font-bold">{{ item.label }}</span>
      <a class="flex flex-column align-items-start" v-if="isMobile">
        <span class="font-normal" v-for="(text) in item.mobileTexts" :key="text.id">{{ text.value }}</span>
      </a>
    </template>
    <template #item="{ item, props }">
      <a class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <Badge v-if="item.badge" class="ml-auto" :value="item.badge" :severity="item.badgeSeverity" />
        <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
      </a>
    </template>
  </Menu>
</template>

<script setup>
const menu = ref();

const toggle = (event) => {
  menu.value.toggle(event);
}
</script>

<script>
import Menu from "primevue/menu";
import Badge from "primevue/badge";
import Avatar from "primevue/avatar";
import Menubar from "primevue/menubar";
import {ref} from "vue";

export default {
  name: "CustomHeader",
  props: [
    'isMobile'
  ],
  components: {
    Menu,
    Badge,
    Avatar,
    Menubar
  },
  data() {
    return {
      items: ref([{label: "Chats", items: []}]),
      me: '',
      username: '',
      role: '',
      itemsProfile: ref([]),
      currentPage: '',
      chatInterval: null
    }
  },
  methods: {
    init: async function() {
      if(this.$cookies.get("token")) {
        sessionStorage.setItem("token",this.$cookies.get("token"));
      } else if(!sessionStorage.getItem("token")) {
        this.logout();
        return;
      }
      await this.$httpAuthGet(this, "/api/users/me", {}).then(async (response) => {
        sessionStorage.setItem("me", await JSON.stringify(response.data));
        this.username = await response.data.username;
        this.role = await response.data.roleDisplayName;
        this.currentPage = sessionStorage.getItem('currentPage')
        sessionStorage.setItem("isLogged", true);

        this.me = await JSON.parse(sessionStorage.getItem("me"));
        this.username = this.me.username;
        this.role = this.me.roleDisplayName;

        await this.$httpAuthGet(this, "/api/chats/", {}).then((response) => {
          this.loadChat(response.data);
        });

        await this.$WS.listen('/someChatChange', async (message) => {
          this.loadChat(JSON.parse(message.body));
        });

        this.itemsProfile.push({
              label: 'Profile',
              mobileTexts: [ {value: this.username}, {value: this.role} ],
              items: [
                { label: 'Settings', icon: 'pi pi-cog' },
                { label: 'Messages', icon: 'pi pi-inbox', badge: 0 },
                { label: 'Logout', icon: 'pi pi-sign-out', command: () => { this.logout(); }},
              ]
            });

        if(this.me && this.me.grants.includes('ADMIN_PANEL')) {
          this.itemsProfile.push({
            label: 'Admin panel',
            items: [
              { label: 'Dashboard', icon: 'pi pi-desktop' },
              { label: 'Reports', icon: 'pi pi-exclamation-triangle', badge: 3, badgeSeverity: 'danger' }
            ]
          });
        }
        if(sessionStorage.getItem("currentPage") === 'Home') {
          this.$getParent(this, "App").$refs.homepage.$forceUpdate();
        }
      }).catch(() => {
        this.logout();
      });
    },
    loadChat: function(data) {
      let chats = [];
      data.forEach( (chat) => {
        let x = {
          id: chat.chatId,
          goto: 'UserChat',
          label: chat.name,
          customDescription: chat.customDescription,
          icon: 'pi pi-comments',
          badgeSeverity: chat.status==='connected' ? 'success' : 'danger',
          badgeValue: chat.toSee > 0 ? chat.toSee : null
        }
        chats.push(x);
      });
      sessionStorage.setItem("chats", JSON.stringify(chats));
      this.items[0].items = chats;
      if(this.isMobile) {
        let homepage = this.$getParent(this, "App").$refs.homepage;
        if (homepage) {
          homepage.$forceUpdate();
        }
      }
    },
    logout: function() {
      this.$WS.leaveAll();
      sessionStorage.clear();
      this.$cookies.remove("token");
      this.$getParent(this, "App").$forceUpdate();
    },
    setSection: function(x) {
      this.$setSection(this, x)
    }
  },
  mounted() {
    this.init();
  },
  unmounted() {
    this.$WS.leave('/someChatChange');
  }
}
</script>

<style>
  .p-menu{
    padding: 0.25rem 0 0 0;
  }

  .p-submenu-header {
    text-align: left;
  }

  .p-menu-start {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 5%;
  }

  .p-menu-list {
    height: 60%;
  }

  .p-menu-end {
    justify-content: center;
    align-items: end;
    display: flex;
    height: 35%;
  }

  .p-menu-end:has(> #profile) {
    justify-content: start;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    background: unset;
    color: unset;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #81A1C1;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    background: unset;
    color: unset;
  }

  .selected {
    background: var(--gray-50);
  }
</style>