<template>
  <div class="absolute w-full h-full bg-gray-50 opacity-70 z-2" v-if="isVisible">
    <div id="spinner" class="absolute" style="bottom: 30px; right: 30px">
      <ProgressSpinner />
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "LoaderAnimation",
  data() {
    return {
      isVisible: false
    }
  },
  components: {
    ProgressSpinner
  },
  methods: {
    hide: function() {
      this.isVisible = false;
    },
    show: function() {
      this.isVisible = true;
    }
  }
}
</script>

<style scoped>

</style>