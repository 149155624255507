<template>
  <div class="md:flex justify-content-center align-items-center w-full h-full bg-cyan-100 hidden" v-if="!isMobile">
    <Card>
      <template #title> Start conversation! </template>
      <template #content>
        <p class="m-0">
          Use the menu on the left to select the chat <br />
          and start your anonymous conversation!
        </p>
      </template>
    </Card>
  </div>
  <div class="flex justify-content-start align-items-start w-full h-full bg-cyan-100 md:hidden" v-else>
    <TabView class="w-full h-full">
      <TabPanel header="Chats">
        <a class="relative overflow-hidden w-full p-link flex align-items-center text-color hover:surface-200 border-noround border-bottom-1"
           @click="setSection(item.goto + (item.id ? '#' + item.id : '') )"
           v-for="(item) in chats" :key="item.id">
          <span class="flex-none flex align-items-center p-4" :class="item.icon" />
          <span class="flex-grow-1 flex flex-column align-items-starter">
            <span class="font-bold">{{ item.label }}</span>
            <span>{{ item.customDescription }}</span>
          </span>
          <Badge class="mx-4 mt-3 mb-5" :value="item.badgeValue ? item.badgeValue : '&nbsp'" :severity="item.badgeSeverity" />
        </a>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import Card from "primevue/card"
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Badge from 'primevue/badge';

export default {
  name: "HomePage",
  props: [
    'isMobile'
  ],
  components: {
    Card,
    TabView,
    TabPanel,
    Badge
  },
  data() {
    return {
      chats: []
    }
  },
  methods: {
    setSection: function(x) {
      this.$setSection(this, x)
    }
  },
  beforeUpdate() {
    if(sessionStorage.getItem("chats")) {
      this.chats = JSON.parse(sessionStorage.getItem("chats"));
    }
  }
}
</script>

<style>
  .p-tabview-panels {
    padding:0;
    height: 100% !important;
    background: var(--cyan-50);
  }
</style>