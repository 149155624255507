<template>
  <div class="mb-5 m-3" :class="{'w-7':isMobile, 'md:w-4 lg:w-3 xl:w-2':!isMobile}">
    <span class="text-primary font-bold text-left block mb-2">Login</span>
    <form class="text-left">
      <div class="field grid">
        <div class="col">
          <a class="text-left block">Username</a>
          <InputText class="w-12" name="username" v-model="username" autocomplete="name" />
        </div>
      </div>
      <div class="field grid">
        <div class="col">
          <a class="text-left block">Password</a>
          <InputText class="w-12" name="password" type="password" v-model="password" autocomplete="off" />
        </div>
      </div>
      <small class="p-error block mb-2 text-left w-12" style="word-break: break-all">{{ errorMessage }}</small>
      <div class="field grid">
        <div class="col text-left">
          <Checkbox v-model="stayLoggedin" inputId="stayCheck" :binary="true" />
          <label for="stayCheck" class="ml-2"> Stay logged in </label>
        </div>
      </div>
      <Button @click="login" label="Login" size="small" class="w-full mb-2" />
      <Button label="Register" severity="secondary" size="small" class="w-full" v-if="registerEnabled" />
    </form>
  </div>
</template>

<script setup>

</script>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Checkbox from 'primevue/checkbox';

export default {
  name: "LoginForm",
  components: {
    InputText,
    Button,
    Checkbox
  },
  props: [
      'isMobile'
  ],
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      stayLoggedin : false,
      registerEnabled: this.$isMaintenance != null ? !this.$isMaintenance : true
    }
  },
  methods: {
    validate: function(fieldName, value) {
      if(!value) {
        this.errorMessage = fieldName + ' cannot be empty';
        return false;
      }
      return true;
    },
    resetForm: function () {
      this.username = "";
      this.password = "";
      this.errorMessage = "";
    },
    login: function() {
      if(this.validate("Username", this.username) && this.validate("Password", this.password)) {
        let body = `{"username":${JSON.stringify(this.username)}, "password":${JSON.stringify(this.password)}}`;
        this.$httpPost(this, "/api/users/login", body).then((response) => {
          let data = response.data;
          sessionStorage.setItem("isLogged", true);
          sessionStorage.setItem("token", data.token);
          if(this.stayLoggedin) {
            this.$cookies.set("token", data.token);
          }
          this.$setSection(this,'Home');
          this.resetForm();
        }).catch( (err) => {
          if(err.response.status === 404) {
            this.errorMessage = err.response.data;
          } else if(err.response.status === 403 && err.response.data.reason === 'mailcheck') {
            console.log("MAILCHECK");
            this.errorMessage = "mailcheck";
          } else {
            this.errorMessage = "Error occurred, wait few minutes and try again!";
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>