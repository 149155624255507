<template>
    <Loader ref="loader" />
    <div class="md:flex md:justify-content-start h-full w-full" v-if="Boolean(isLogged())">
      <CustomHeader ref="header" :isMobile="isMobile" v-if="isMaintenance"/>
      <div :style="{height:(this.isMobile ? '94%' : '100%')}"
           class="w-full md:w-8 lg:w-9 xl:w-10 bg-cyan-50 flex justify-content-center align-items-start md:align-items-center">
        <Homepage ref="homepage" v-if="checkSection('Home')" :isMobile="isMobile" />
        <UserChat v-if="checkSection('UserChat')" :isMobile="isMobile" />
      </div>
    </div>
    <div class="h-full w-full" v-else>
      <div class="h-full w-full bg-cyan-50 flex flex-column justify-content-center align-items-center">
        <svg width="200" height="20" fill="none" viewBox="0 0 200 20" xmlns="http://www.w3.org/2000/svg" class="center h-2rem">
          <image href="./assets/svg/logo-no-background.svg" x="0" y="0" width="200px" height="20px"/>
        </svg>
        <br />
        <MaintenancePage :isMobile="isMobile" v-if="checkSection('Maintenance')" />
        <LoginForm :isMobile="isMobile" v-else />
      </div>
    </div>

</template>

<script>
import Loader from "@/components/Loader";
import CustomHeader from "@/components/CustomHeader";
import LoginForm from "@/components/LoginForm";
import Homepage from "@/components/Homepage";
import UserChat from "@/components/UserChat";
import MaintenancePage from "@/components/MaintenancePage";

export default {
  name: 'App',
  components: {
    MaintenancePage,
    UserChat,
    Homepage,
    LoginForm,
    Loader,
    CustomHeader
  },
  data() {
    return {
      userAgent: navigator.userAgent,
      isMobile: false,
      bodyHeight: '100%',
      isMaintenance: this.$isMaintenance
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    isLogged: function() {
      return Boolean(sessionStorage.getItem('isLogged') || this.$cookies.get('token'));
    },
    refresh: async function() {
      await this.$forceUpdate();
      if(this.$refs.header) {
        await this.$refs.header.$forceUpdate();
      }
      if(this.$refs.homepage) {
        await this.$refs.homepage.$forceUpdate();
      }
    },
    checkSection: function(x) {
      return this.$checkSection(x);
    },
    checkMobile: function() {
      var w = window.innerWidth;
      if(this.userAgent !== navigator.userAgent) {
        this.userAgent = navigator.userAgent;
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.userAgent);
        if(this.isMobile && w>=768) {
          this.isMobile = false;
        }
        this.$forceUpdate();
      } else {
        if(w<768) {
          this.isMobile = true;
          this.$forceUpdate();
        } else if(this.isMobile) {
          this.isMobile = false;
          this.$forceUpdate();
        }
      }
    },
    handleResize: function() {
      setTimeout(() => {
        this.checkMobile();
      }, 200)

    },
  },
  beforeMount() {
    this.checkMobile();
    if(!this.isLogged()) {
      this.$clearSection();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: unset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

Button:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
