import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import Stomp from 'webstomp-client';
import Cookies from 'vue-cookies';

import App from './App.vue'
import 'primevue/resources/themes/mira/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import BadgeDirective from 'primevue/badgedirective';
import axios from "axios";

const app = createApp(App)//createApp(App);
let baseUrl;
let urlWS;
let isMaintenance;

if (process.env.NODE_ENV === 'production') {
    baseUrl = "https://secretship.net:8443";
    urlWS = "wss://secretship.net:8443/ws";
} else {
    baseUrl = "http://localhost:8081";
    urlWS = "ws://localhost:8081/ws";
}

const WS = [];
WS.stompClient = Stomp.over(new WebSocket(urlWS), { debug: false });
WS.stompClient.connect();
WS.subscription = [];

WS.listen = async function(destination, callback, withUserId = true) {

    let myId = await JSON.parse(sessionStorage.getItem("me")).id;
    let baseDestination = `/secret/${sessionStorage.getItem("token")}`;
    if(withUserId) {
        destination += `/${myId}`;
    }
    destination += `/messages`;

    WS.subscription.push("/secret" + destination);
    await WS.stompClient.subscribe(baseDestination + destination, callback);
}

WS.send = async function(destination, endDestination, body, callback, withUserId = true) {

    let myId = await JSON.parse(sessionStorage.getItem("me")).id;
    let baseDestination = `/secret/${sessionStorage.getItem("token")}`;
    if(withUserId) {
        destination += `/${myId}`;
    }
    destination += endDestination;

    await WS.stompClient.send(baseDestination + destination, body, callback);
}

WS.leave = async function(destination, withUserId = true) {

    if(sessionStorage.getItem("me")) {
        let myId = await JSON.parse(sessionStorage.getItem("me")).id;
        let baseDestination = '/secret';
        if (withUserId) {
            destination += `/${myId}`;
        }
        destination += `/messages`;

        WS.stompClient.unsubscribe(baseDestination + destination);
    }
}

WS.leaveAll = async function() {

    WS.subscription.forEach((sub) => {
        WS.stompClient.unsubscribe(sub);
    });

}

app.config.globalProperties.$WS = WS;

app.config.globalProperties.$getParent = function(me, componentName) {
    let component = null
    let parent = me.$parent
    while (parent && !component) {
        if (parent.$options.name === componentName) {
            component = parent
        }
        parent = parent.$parent
    }
    return component
}

function generateUrl(extensionUrl, parameters) {
    let url = baseUrl + extensionUrl;
    let first = true;
    for(let index in parameters) {
        let parameter = parameters[index];
        if(first) {
            url += '?';
            first = false;
        }
        else
            url += '&';
        url += parameter["key"] + '=' + parameter["value"];
    }
    return url;
}

app.config.globalProperties.$httpGet = async function(me, extensionUrl, parameters, withAnimation = true) {
    if(withAnimation) {
        startLoading(me);
    }
    return axios.get(generateUrl(extensionUrl, parameters)).finally( () => {
        if(withAnimation) {
            stopLoading(me);
        }
    });
}

app.config.globalProperties.$httpPost = async function(me, extensionUrl, body, parameters, withAnimation = true) {
    if(withAnimation) {
        startLoading(me);
    }
    return axios.post(generateUrl(extensionUrl, parameters), body, {
            headers: {
                'Content-Type': 'application/json'
            }
    }).finally( () => {
        if(withAnimation) {
            stopLoading(me);
        }
    });
}

app.config.globalProperties.$httpAuthGet = async function(me, extensionUrl, parameters, withAnimation = true) {
    if(sessionStorage.getItem("token")) {
        if(withAnimation) {
            startLoading(me);
        }
        return axios.get(
            generateUrl(extensionUrl, parameters), {
                headers: {
                    'authorization': 'Bearer ' + sessionStorage.getItem("token")
                }
        }).finally(() => {
            if(withAnimation) {
                stopLoading(me);
            }
        });
    } else {
        return null;
    }
}

app.config.globalProperties.$httpAuthPost = async function(me, extensionUrl, body, parameters, withAnimation = true) {
    if(sessionStorage.getItem("token")) {
        if(withAnimation) {
            startLoading(me);
        }
        return axios.post(
            generateUrl(extensionUrl, parameters), body,{
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + sessionStorage.getItem("token")
                }}).finally( () => {
            if(withAnimation) {
                stopLoading(me);
            }
        });
    } else {
        return null;
    }
}

await axios.get(generateUrl("/api/status/", {})).then(async (response) => {
    let data = await response.data;
    isMaintenance = data.maintenance != null ? data.maintenance : false;
    app.config.globalProperties.$isMaintenance = isMaintenance;
})

app.config.globalProperties.$clearSection = function clearSection() {
    sessionStorage.setItem("currentPage", isMaintenance ? 'Maintenance' : 'Home');
}

app.config.globalProperties.$checkSection = function checkSection(section) {
    if(!sessionStorage.getItem("currentPage")) {
        sessionStorage.setItem("currentPage", isMaintenance ? 'Maintenance' : 'Home');
    }
    let page = sessionStorage.getItem("currentPage").split('#');
    return page[0] === section
}

app.config.globalProperties.$setSection = function setSection(me, section) {
    sessionStorage.setItem("currentPage", section);
    this.$getParent(me, "App").refresh();
}

app.config.globalProperties.$intervals = [];

function startLoading(me) {
    let loader = app.config.globalProperties.$getParent(me, "App").$refs.loader;
    loader.show();
}

function stopLoading(me) {
    let loader = app.config.globalProperties.$getParent(me, "App").$refs.loader;
    loader.hide();
}

app.config.globalProperties.$app = app;

app.use(PrimeVue);
app.use(Cookies);
app.directive('badge', BadgeDirective);
app.mount('#app');
